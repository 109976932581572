const useGeoMath = () => {
  const getBoundingBox = (centerPoint: any, distance: any) => {
    var MIN_LAT,
      MAX_LAT,
      MIN_LON,
      MAX_LON,
      R,
      radDist,
      degLat,
      degLon,
      radLat,
      radLon,
      minLat,
      maxLat,
      minLon,
      maxLon,
      deltaLon;
    if (distance < 0) {
      return "Illegal arguments";
    }
    const degToRad = function (that: any) {
      return that * (Math.PI / 180);
    };
    const radToDeg = function (that: any) {
      return (180 * that) / Math.PI;
    };
    MIN_LAT = degToRad(-90);
    MAX_LAT = degToRad(90);
    MIN_LON = degToRad(-180);
    MAX_LON = degToRad(180);
    R = 6378.1;
    radDist = distance / R;
    degLat = centerPoint[0];
    degLon = centerPoint[1];
    radLat = degToRad(degLat);
    radLon = degToRad(degLon);
    minLat = radLat - radDist;
    maxLat = radLat + radDist;
    minLon = void 0;
    maxLon = void 0;
    deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
    if (minLat > MIN_LAT && maxLat < MAX_LAT) {
      minLon = radLon - deltaLon;
      maxLon = radLon + deltaLon;
      if (minLon < MIN_LON) {
        minLon = minLon + 2 * Math.PI;
      }
      if (maxLon > MAX_LON) {
        maxLon = maxLon - 2 * Math.PI;
      }
    } else {
      minLat = Math.max(minLat, MIN_LAT);
      maxLat = Math.min(maxLat, MAX_LAT);
      minLon = MIN_LON;
      maxLon = MAX_LON;
    }
    return [
      radToDeg(minLon),
      radToDeg(minLat),
      radToDeg(maxLon),
      radToDeg(maxLat),
    ];
  };
  const getBoundingBoxMap = (centerPoint: any, distance: any) => {
    var MIN_LAT,
      MAX_LAT,
      MIN_LON,
      MAX_LON,
      R,
      radDist,
      degLat,
      degLon,
      radLat,
      radLon,
      minLat,
      maxLat,
      minLon,
      maxLon,
      deltaLon;
    if (distance < 0) {
      return null;
    }
    const degToRad = function (that: any) {
      return that * (Math.PI / 180);
    };
    const radToDeg = function (that: any) {
      return (180 * that) / Math.PI;
    };
    MIN_LAT = degToRad(-90);
    MAX_LAT = degToRad(90);
    MIN_LON = degToRad(-180);
    MAX_LON = degToRad(180);
    R = 6378.1;
    radDist = distance / R;
    degLat = centerPoint[0];
    degLon = centerPoint[1];
    radLat = degToRad(degLat);
    radLon = degToRad(degLon);
    minLat = radLat - radDist;
    maxLat = radLat + radDist;
    minLon = void 0;
    maxLon = void 0;
    deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
    if (minLat > MIN_LAT && maxLat < MAX_LAT) {
      minLon = radLon - deltaLon;
      maxLon = radLon + deltaLon;
      if (minLon < MIN_LON) {
        minLon = minLon + 2 * Math.PI;
      }
      if (maxLon > MAX_LON) {
        maxLon = maxLon - 2 * Math.PI;
      }
    } else {
      minLat = Math.max(minLat, MIN_LAT);
      maxLat = Math.min(maxLat, MAX_LAT);
      minLon = MIN_LON;
      maxLon = MAX_LON;
    }
    return [
      [radToDeg(minLat), radToDeg(minLon)],
      [radToDeg(maxLat), radToDeg(maxLon)],
    ];
  };
  const fmod = (X: number, Y: number) => {
    return X % Y;
  };
  const getBearing = (
    coordHere: { latitude: number; longitude: number },
    coordThere: { latitude: number; longitude: number }
  ) => {
    const lat1 = coordHere.latitude; // source latitude, example data
    const lon1 = coordHere.longitude; // source longitude

    const lat2 = coordThere.latitude; // destination latitude
    const lon2 = coordThere.longitude; // destination longitude

    const lat1Rad = (lat1 * Math.PI) / 180;
    const lat2Rad = (lat2 * Math.PI) / 180;

    const dLon = ((lon2 - lon1) * Math.PI) / 180;

    const y = Math.sin(dLon) * Math.cos(lat2Rad);
    const x =
      Math.cos(lat1Rad) * Math.sin(lat2Rad) -
      Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(dLon);

    const bearingRad = Math.atan2(y, x);
    const bearing = fmod((bearingRad * 180) / Math.PI + 360, 360);
    return bearing;
  };

  return { getBoundingBox, getBoundingBoxMap, getBearing };
};

export default useGeoMath;
