import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AodIcon from "@mui/icons-material/Aod";
import MapIcon from "@mui/icons-material/Map";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import { Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectApp,
  selectLocalApps,
  selectNavItem,
  selectZone,
  selectZoneApp,
  setNavItem,
  setSection,
  setTheme,
} from "../../store/appReducerSlice";
import { useState } from "react";
export const Navigation = () => {
  const dispatch = useAppDispatch();
  const inzone = useAppSelector(selectLocalApps);
  const zone = useAppSelector(selectZone);
  const zoneApp = useAppSelector(selectZoneApp);
  const screen = useAppSelector(selectNavItem);
  const app = useAppSelector(selectApp);

  return (
    <>
      <div style={{ height: "56px", display: "block", zIndex: 1002 }}>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: "0px",
          }}
          elevation={3}
        >
          <BottomNavigation
            sx={{ mr: 1, ml: 1 }}
            value={screen}
            onChange={(event: React.SyntheticEvent, newValue: string) => {
              dispatch(setNavItem(newValue));
            }}
          >
            <BottomNavigationAction
              label="Map"
              value="map"
              icon={<MapIcon />}
              onClick={() => {
                dispatch(setTheme(app));
                dispatch(setSection("map"));
              }}
            />
            {zone && zone.length == 1 && zone[0]?.xr && (
              <BottomNavigationAction
                label="AR"
                value="xr"
                icon={<ViewInArIcon />}
                onClick={() => {
                  dispatch(setSection("xr"));
                }}
              />
            )}
            <BottomNavigationAction
              label="Main"
              value="main"
              icon={<AodIcon />}
              onClick={() => {
                console.log(inzone.length);
                if (zoneApp) {
                  dispatch(setSection("zone"));
                } else if (inzone?.length > 1) {
                  dispatch(setSection("zone-select"));
                } else {
                  dispatch(setSection("outside-zone"));
                }
              }}
            />
            {inzone && inzone?.length > 1 && (
              <BottomNavigationAction
                label={inzone.length + " Zones"}
                value="zones"
                icon={<LocationOnIcon />}
                onClick={() => {
                  dispatch(setSection("zone-select"));
                }}
              />
            )}
            <BottomNavigationAction
              label="Settings"
              value="settings"
              icon={<SettingsIcon />}
              onClick={() => {
                dispatch(setSection("settings"));
              }}
            />
          </BottomNavigation>
        </Paper>
      </div>
    </>
  );
};
