import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { IconButton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useDeviceOrientation } from "../../hooks/deviceOrientation";
import { useState } from "react";
import { Preview } from "../preview/preview";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectApp,
  setMapPoint,
  setTheme,
  setSection,
  setNavItem,
} from "../../store/appReducerSlice";
import useGeoMath from "../../hooks/geoMath";
interface props {
  // deviceOrientationEvents: any;
  zone: any;
  // app: any;
}

export const Compass = ({ zone }: props) => {
  const { getDir, events } = useDeviceOrientation();
  const { getBearing } = useGeoMath();
  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const [preview, setPreview] = useState<boolean>(false);
  const setMap = (zone: any) => {
    // sthi will warp to the map
    console.log(zone);
  };

  // @ts-ignore
  const coords = window.coords;

  return (
    <>
      <Preview
        preview={preview}
        setPreview={setPreview}
        zone={zone}
        mapExit={true}
        app={app}
      />

      <Box
        component="div"
        textAlign="center"
        sx={{ mt: 3, mb: 3, ml: 2, mr: 2 }}
      >
        {events && (events.alpha || events.alpha == 0) && (
          <ArrowCircleUpIcon
            sx={{
              transform: `rotate(${events.compass + zone.bearing}deg)`,
              fontSize: 100,
              color: "white",
            }}
          />
        )}

        <Typography variant="h3" sx={{ mb: 2 }}>
          {zone.zone.id}
        </Typography>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">
            {Math.round(zone.distance) / 1000 + "km " + getDir(zone.bearing)}
          </Typography>
          <div>
            <>
              <IconButton
                onClick={() => {
                  dispatch(setSection("map"));
                  dispatch(setNavItem("map"));
                  dispatch(setMapPoint(zone.zone));
                }}
              >
                <FmdGoodIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  const _app = app.locations.find((location: any) => {
                    return location.settings.zones.find((_zone: any) => {
                      return _zone?._id === zone.zone?._id;
                    });
                  });
                  dispatch(setTheme(_app));
                  setPreview(true);
                }}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </>
          </div>
        </Stack>
      </Box>
    </>
  );
};
