import { Billboard, Text } from "@react-three/drei";
import { Controllers, XR } from "@react-three/xr";
import _ from "lodash";
import { Fragment, useMemo } from "react";
import { useDeviceOrientation } from "../../hooks/deviceOrientation";
import useGeoMath from "../../hooks/geoMath";
import { selectZone } from "../../store/appReducerSlice";
import { useAppSelector } from "../../store/hooks";

export const ArCompass = () => {
  const zone = useAppSelector(selectZone)[0];
  const { getBearing } = useGeoMath();
  // @ts-ignore
  const { events } = useDeviceOrientation();
  // @ts-ignore
  const coords = window.coords;
  // we needs make the compass available for android so we have to make a calibration screen if unavaialble
  const compass = 0; //events.compass || 0;
  console.log(events);
  console.log(zone);
  const pointLocations = useMemo(() => {
    return zone.xrCompassLocations.map((location: any) => {
      const bearing =
        compass -
        getBearing(
          { latitude: coords.latitude, longitude: coords.longitude },
          { latitude: location.latitude, longitude: location.longitude }
        );
      const finalBearing = (bearing * Math.PI) / 180;
      const radius = 200;
      let x = radius * Math.cos(finalBearing);
      let y = radius * Math.sin(finalBearing);
      x = Math.round(x * 100) / 100;
      y = Math.round(y * 100) / 100;
      return {
        ...location,
        x: x,
        y: y,
        z: 0,
      };
    });
  }, []);

  //
  return (
    <XR referenceSpace="local">
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      {
        //loop the locations

        pointLocations &&
          pointLocations.map((location: any) => (
            <Fragment key={_.uniqueId()}>
              <Billboard follow position={[location.x, location.y, 0]}>
                <Text
                  scale={[10, 10, 10]}
                  color="white"
                  anchorX="center"
                  anchorY="middle"
                >
                  {location.title}
                </Text>
              </Billboard>
            </Fragment>
          ))
      }
      <Controllers />
    </XR>
  );
};
