import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  selectCapabilities,
  selectZone,
  selectZoneApp,
  setTheme,
} from "../../store/appReducerSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ZoneXR } from "../zone/zoneXR";

export const XR = () => {
  const capabilities = useAppSelector(selectCapabilities);
  const zoneApp = useAppSelector(selectZoneApp);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTheme(zoneApp));
  }, []);

  return (
    <>
      <Box
        component="div"
        sx={{
          backgroundImage: zoneApp?.settings?.style?.backgroundImage,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        {capabilities.xr ? (
          <ZoneXR />
        ) : (
          <Typography variant="h3" sx={{ textAlign: "center", m: 2 }}>
            XR NOT SUPPORTED BY DEVICE
          </Typography>
        )}
      </Box>
    </>
  );
};
