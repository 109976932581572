import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import type { RootState } from "./store";

// Define a type for the slice state
interface appState {
  lastSection: string | null;
  section: string | null;
  app: any;
  zone: any;
  page: any;
  geolocation: any;
  theme: any;
  geolocationEnabled: boolean;
  geolocationAvailable: boolean;
  capabilities: any;
  localApps: any;
  title: string;
  zoneApp: any;
  welcome: any;
  navItem: string;
  mapPoint: any;
  statistics: any;
  lastStatistics: any;
  hash: string;
}

// Define the initial state using that type
const initialState: appState = {
  lastSection: null,
  section: "welcome",
  geolocation: false,
  app: {},
  zone: {},
  page: {},
  theme: null,
  geolocationEnabled: false,
  geolocationAvailable: false,
  capabilities: {},
  localApps: [],
  title: "",
  zoneApp: null,
  welcome: [],
  navItem: "main",
  mapPoint: null,
  statistics: null,
  lastStatistics: null,
  hash: "",
};

export const app = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setSection: (state, action: PayloadAction<string | null>) => {
      state.lastSection = state.section;
      state.section = action.payload;
    },
    setLastSection: (state, action: PayloadAction<string | null>) => {
      state.lastSection = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },

    setZone: (state, action: PayloadAction<any>) => {
      state.zone = action.payload;
    },

    setPage: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
    },

    setApp: (state, action: PayloadAction<any>) => {
      state.app = action.payload;
    },

    setZoneApp: (state, action: PayloadAction<any>) => {
      state.zoneApp = action.payload;
    },

    setTheme: (state, action: PayloadAction<any>) => {
      state.theme = action.payload;
    },
    setLocalApps: (state, action: PayloadAction<any>) => {
      state.localApps = action.payload;
    },
    setCapabilities: (state, action: PayloadAction<any>) => {
      state.capabilities = action.payload;
    },
    addWelcome: (state, action: PayloadAction<any>) => {
      state.welcome.push(action.payload);
    },

    setGeoocationEnabled: (state) => {
      state.geolocationEnabled = true;
    },
    setGeoocationAvailable: (state) => {
      state.geolocationEnabled = true;
    },

    setNavItem: (state, action: PayloadAction<any>) => {
      state.navItem = action.payload;
    },

    setMapPoint: (state, action: PayloadAction<any>) => {
      state.mapPoint = action.payload;
    },
    setHash: (state, action: PayloadAction<any>) => {
      state.hash = action.payload;
    },

    setStatistics: (state, action: PayloadAction<any>) => {
      state.lastStatistics = state.statistics;
      state.statistics = action.payload;
    },
  },
});

export const {
  setSection,
  setLastSection,
  setZone,
  setPage,
  setApp,
  setTheme,
  setGeoocationEnabled,
  setGeoocationAvailable,
  setCapabilities,
  setLocalApps,
  setTitle,
  setZoneApp,
  addWelcome,
  setNavItem,
  setMapPoint,
  setStatistics,
  setHash,
} = app.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSection = (state: RootState) => state.app.section;
export const selectLastSection = (state: RootState) => state.app.lastSection;
export const selectZone = (state: RootState) => state.app.zone;
export const selectPage = (state: RootState) => state.app.page;
export const selectApp = (state: RootState) => state.app.app;
export const selectZoneApp = (state: RootState) => state.app.zoneApp;
export const selectTheme = (state: RootState) => state.app.theme;
export const selectLocalApps = (state: RootState) => state.app.localApps;
export const selectCapabilities = (state: RootState) => state.app.capabilities;
export const selectGeolocationEnabled = (state: RootState) =>
  state.app.geolocationEnabled;
export const selectGeolocationAvailable = (state: RootState) =>
  state.app.geolocationAvailable;
export const selectTitle = (state: RootState) => state.app.title;
export const selectWelcome = (state: RootState) => state.app.welcome;
export const selectNavItem = (state: RootState) => state.app.navItem;
export const selectMapPoint = (state: RootState) => state.app.mapPoint;
export const selectStatistics = (state: RootState) => state.app.statistics;
export const selectLastStatistics = (state: RootState) =>
  state.app.lastStatistics;
export const selectHash = (state: RootState) => state.app.hash;

export default app.reducer;
