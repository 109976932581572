import { useState } from "react";

export const useDeviceOrientation = () => {
    const [events, setEvents] = useState<any>()

    const handler = (e: any) => {

        const event = {
            compass: e.webkitCompassHeading || Math.abs(e.alpha - 360),
            events: e
        }
        setEvents(event)
    }


    const isIOS = (
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/)
    );


    if (isIOS) {
        // @ts-ignore 
        DeviceOrientationEvent.requestPermission()
            .then((response: any) => {
                if (response === "granted") {
                    window.addEventListener("deviceorientation", handler, true);
                } else {
                    alert("has to be allowed!");
                }
            })
            .catch(() => alert("not supported"));
    } else {
        window.addEventListener("deviceorientation", handler, true);
    }


    const getDir = (bearing: any) => {
        const dirs = ['North', 'North East', 'East', 'South East', 'South', 'South West', 'West', 'North West', 'North']
        return dirs[Math.round(bearing / 45)];
    }


    return { events, getDir }
}