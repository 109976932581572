import {
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  Grid,
  IconButton,
  Stack,
  ThemeProvider,
} from "@mui/material";
import useTheme from "../../hooks/theme";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Page } from "../page/page";
import { PreviewAppBar } from "./previewAppBar";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import {
  setMapPoint,
  setTheme,
  setSection,
  setNavItem,
} from "../../store/appReducerSlice";

export const Preview = ({ preview, setPreview, zone, app, mapExit }: any) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<any>(zone.zone);
  useEffect(() => {
    setPage(zone.zone);
  }, [zone]);

  const _app = useMemo(() => {
    return app.locations.find((location: any) => {
      return location.settings.zones.find((_zone: any) => {
        return _zone?._id === zone.zone?._id;
      });
    });
  }, [app.locations, zone.zone]);
  return (
    <Drawer
      PaperProps={{
        style: {
          backgroundImage: _app.settings.style.backgroundImage,
          zIndex: 9000,
        },
      }}
      open={preview}
      onClose={() => {
        dispatch(setTheme(null));
        setPreview(false);
      }}
    >
      <PreviewAppBar app={app} zone={zone} setPage={setPage} />
      <Page page={page}></Page>

      <BottomNavigation
        style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 9001 }}
      >
        {mapExit && (
          <BottomNavigationAction
            onClick={() => {
              dispatch(setTheme(null));
              setPreview(false);
              dispatch(setSection("map"));
              dispatch(setNavItem("map"));
              dispatch(setMapPoint(zone.zone));
            }}
            label="Recents"
            icon={<FmdGoodIcon />}
          />
        )}
        <BottomNavigationAction
          onClick={() => {
            dispatch(setTheme(null));
            setPreview(false);
          }}
          label="Recents"
          icon={<ExitToAppOutlinedIcon />}
        />
      </BottomNavigation>
    </Drawer>
  );
};
