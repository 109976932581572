import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  Link,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Avatar,
  Box,
} from "@mui/material";
import { useEffect } from "react";
import {
  selectApp,
  selectCapabilities,
  setTheme,
} from "../../store/appReducerSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const Settings = () => {
  const dispatch = useAppDispatch();
  const capabilities = useAppSelector(selectCapabilities);
  const app = useAppSelector(selectApp);
  useEffect(() => {
    dispatch(setTheme(app));
  }, []);
  // @ts-ignore
  const coords = window.coords;

  return (
    <>
      <Box
        component="div"
        sx={{
          backgroundImage: document.body.style.backgroundImage,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
        }}
      >
        <Typography variant="h1" sx={{ mt: 3, mb: 2, textAlign: "center" }}>
          Settings
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Location Accuracy"
              secondary={coords.accuracy}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="XR Enabled Device"
              secondary={capabilities.xr ? "Full Feature Mode" : "Marker Mode"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Device Orientation"
              secondary={capabilities.giro ? "Available" : "Disabled"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Camera"
              secondary={capabilities.camera ? "Available" : "Disabled"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Gps"
              secondary={capabilities.gps ? "Available" : "Disabled"}
            />
          </ListItem>
        </List>
        <Typography variant="h2" sx={{ mt: 3, mb: 2, textAlign: "center" }}>
          About
        </Typography>
        <Typography
          variant="body1"
          sx={{ mr: 2, ml: 2, mb: 1, textAlign: "center" }}
        >
          <strong>Zonify</strong>
        </Typography>
        <Typography
          variant="body1"
          sx={{ mr: 2, ml: 2, mb: 1, textAlign: "center" }}
        >
          <Link target="blank" href="https://zonify.co">
            zonify.co
          </Link>
        </Typography>
        <Typography
          variant="body2"
          sx={{ mr: 2, ml: 2, mb: 5, textAlign: "center" }}
        >
          &copy; 2022 demaine
        </Typography>
      </Box>
    </>
  );
};
