import { useState } from "react";
import axios from "axios";

const API_ENPOINT = process.env.REACT_APP_API_ENDPOINT;
const useAPI = () => {
  async function getLocations(hash: any) {
    try {
      const response = await axios.get(API_ENPOINT + "/locations");
      return {
        locations: response.data.locations,
        title: response.data.title,
        hash: hash,
      };
    } catch (error) {
      console.log(error);
      return "";
    }
  }
  const [hash, setHash] = useState<any>("X");
  async function getHash(hash: any) {
    try {
      let formData = new FormData();
      formData.append("hash", hash);
      const response = await axios.post(API_ENPOINT + "/hash", formData);

      if (response.data.hash) {
        if (response.data.hash !== hash) {
          return getLocations(response.data.hash);
        }
      }
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  const postStatistics = async (statistics: any) => {
    try {
      let formData = new FormData();
      Object.entries(statistics).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      const response = await axios.post(
        API_ENPOINT + "/set-statistics",
        formData
      );
      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  return { getLocations, getHash, postStatistics };
};

export default useAPI;
