import { useState, useEffect, useRef, useMemo } from "react";
import { Grid, Button, Typography, Box } from "@mui/material";
import { InZone } from "./inZone";
import _ from "lodash";

import { Page } from "../page/page";
import { animated, useSpring } from "@react-spring/web";
import {
  addWelcome,
  selectPage,
  selectWelcome,
  selectZone,
  selectZoneApp,
  setPage,
  setTheme,
} from "../../store/appReducerSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ZoneAppBar } from "./zoneAppBar";
export interface zoneProps {
  app: any;
  coords: any;
  deviceOrientationEvents: any;
}
export const Zone = () => {
  const dispatch = useAppDispatch();

  const zoneApp = useAppSelector(selectZoneApp);
  const page = useAppSelector(selectPage);
  const welcome = useAppSelector(selectWelcome);
  const inzone = useAppSelector(selectZone);

  useEffect(() => {
    dispatch(setPage(null));
    dispatch(setTheme(zoneApp));
  }, []);

  const animateWelcome = useSpring({
    opacity: welcome && inzone.length ? 1 : 0,
    y: welcome && inzone.length ? 0 : 24,
  });

  const animatePage = useSpring({
    opacity: inzone.length ? 1 : 0,
    y: inzone.length ? 0 : 24,
  });

  return (
    <>
      <Grid
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          pb: 7,
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
        }}
      >
        {welcome.indexOf(inzone[0]._id) == -1 && inzone.length ? (
          <>
            <animated.div style={animateWelcome}>
              <Grid item>
                {zoneApp?.settings?.logo && (
                  <>
                    <Box component="div" textAlign="center">
                      <Box
                        component="img"
                        sx={{
                          height: 300,
                          width: 300,
                          maxHeight: { xs: 300, md: 250 },
                          maxWidth: { xs: 300, md: 250 },
                          mt: 4,
                        }}
                        src={zoneApp.settings.logo.src}
                      />
                    </Box>
                  </>
                )}
                <Box component="div" textAlign="center">
                  <Typography
                    variant="h1"
                    component="div"
                    sx={{ flexGrow: 1, mt: 2, mb: 6 }}
                  >
                    {zoneApp?.settings?.name}
                  </Typography>
                </Box>

                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    textAlign: "center",
                    ml: 2,
                    mr: 2,
                    flexGrow: 1,
                  }}
                >
                  {zoneApp?.settings?.welcomeText}
                </Typography>
                <Box component="div" textAlign="center" sx={{ mb: 5 }}>
                  <Button
                    id="addSubmit"
                    sx={{ mt: 4 }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (welcome.indexOf(inzone[0]._id) == -1) {
                        dispatch(addWelcome(inzone[0]._id));
                      }
                    }}
                  >
                    OK
                  </Button>
                </Box>
              </Grid>
            </animated.div>
          </>
        ) : page?.content ? (
          <>
            <ZoneAppBar />
            <Grid>
              <animated.div style={animatePage}>
                <Page page={page} />
              </animated.div>
            </Grid>
          </>
        ) : (
          <>
            <ZoneAppBar />
            <Grid item>
              <InZone inzone={inzone} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
