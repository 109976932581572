import { useEffect, useState, useMemo } from "react";
import { useGeolocated } from "react-geolocated";
import useGeoMath from "../../hooks/geoMath";
import { navigatorData } from "../../libs/navigator";
import {
  selectApp,
  selectCapabilities,
  setLocalApps,
  setSection,
  setStatistics,
  setTitle,
  setZone,
  setZoneApp,
} from "../../store/appReducerSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const Gps = () => {
  const capabilities = useAppSelector(selectCapabilities);
  const app = useAppSelector(selectApp);
  const dispatch = useAppDispatch();
  const [lastZone, setLastZone] = useState<string>("");
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
    watchPosition: true,
  });
  const { getBoundingBox } = useGeoMath();

  var locations: any[] = [];
  // @ts-ignore
  window.coords = coords; // put coords outside of react

  const _navigator = useMemo(() => {
    return navigatorData();
  }, []);

  useEffect(() => {
    if (coords) {
      const _inzone: any[] = [];
      for (var i in app.locations) {
        for (var j in app.locations[i].settings.zones) {
          const bounds = getBoundingBox(
            [coords?.latitude, coords?.longitude],
            app.locations[i].settings.zones[j].markerPhysicalDimension
          );
          var zone = app.locations[i].settings.zones[j];

          if (zone.lat > bounds[1]) {
            if (zone.lat < bounds[3]) {
              if (zone.long > bounds[0]) {
                if (zone.long < bounds[2]) {
                  locations.push([
                    app.locations[i],
                    app.locations[i].settings.zones[j],
                  ]);
                  var _app = app.locations[i];
                  _inzone.push(app.locations[i].settings.zones[j]);
                }
              }
            }
          }
        }
      }

      const statistics = {
        browser: _navigator,
        zoneApp: "",
        zone: "",
        section: "",
        timedate: new Date().getTime(),
        camera: capabilities?.camera,
        gps: capabilities?.gps,
        xr: capabilities?.xr,
        giro: capabilities?.giro,
      };

      dispatch(setLocalApps(locations));
      if (locations.length == 0) {
        if (lastZone !== "outside-zone") {
          setLastZone("outside_zone");
          statistics.section = "outside-zone";
          dispatch(setStatistics(statistics));
          dispatch(setSection("outside-zone"));
        }
      }
      if (locations.length == 1) {
        if (lastZone !== _inzone[0]._id) {
          setLastZone(_inzone[0]._id);
          statistics.section = "zone";
          statistics.zoneApp = _app.id;
          statistics.zone = _inzone[0]._id;
          dispatch(setStatistics(statistics));

          dispatch(setSection("zone"));
          dispatch(setTitle(app.name));
          dispatch(setZoneApp(_app));
          dispatch(setZone(_inzone));
        }
      }
      if (locations.length > 1) {
        if (lastZone !== "zone-select") {
          setLastZone("zone-select");
          statistics.section = "zone-select";
          dispatch(setStatistics(statistics));

          dispatch(setSection("zone-select")); // this should be set zone select
          dispatch(setTitle(app.name));
          dispatch(setZoneApp(null));
          dispatch(setZone(_inzone));
        }
      }
    }
  }, [coords, app.hash]);

  return null;
};
