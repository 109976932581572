import { Box, Grid, Stack, Typography } from "@mui/material";
import { Rings } from "react-loader-spinner";
import { selectGeolocationAvailable } from "../../store/appReducerSlice";
import { useAppSelector } from "../../store/hooks";

export const GpsScreens = () => {
  const geolocationAvailable = useAppSelector(selectGeolocationAvailable);
  const geolocationEnabled = useAppSelector(selectGeolocationAvailable);

  return (
    <>
      {!geolocationAvailable && !geolocationEnabled && (
        <Box component="div" textAlign="center" sx={{ w: "100%" }}>
          <Grid item>
            <Typography
              variant="h1"
              component="div"
              sx={{ flexGrow: 1, padding: 3 }}
            >
              Your browser does not support location services
            </Typography>
          </Grid>
        </Box>
      )}
      {geolocationAvailable && !geolocationEnabled && (
        <Box component="div" textAlign="center" sx={{ w: "100%" }}>
          <Grid item>
            <Rings
              height="80"
              width="80"
              color="#fff"
              radius="6"
              wrapperStyle={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              wrapperClass=""
              visible={true}
              ariaLabel="rings-loading"
            />
            <Typography
              variant="h4"
              component="div"
              sx={{ flexGrow: 1, padding: 3 }}
            >
              Waiting for location
            </Typography>
          </Grid>
        </Box>
      )}
      {geolocationEnabled && (
        <Box component="div" textAlign="center" sx={{ w: "100%" }}>
          <Rings
            height="180"
            width="180"
            color="#fff"
            radius="6"
            wrapperStyle={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
          &nbsp;
        </Box>
      )}
    </>
  );
};
