import createTheme from "@mui/material/styles/createTheme";

const useTheme = (app: any) => {
  return makeTheme(app);
};
export const makeTheme = (app: any) => {
  var style = app?.settings?.style;
  if (style) {
    var theme = createTheme({
      palette: {
        mode: "dark",
        primary: {
          main: style.primaryColor,
        },
        text: {
          primary: style.textColorPrimary,
          secondary: style.textColorSecondary,
          disabled: style.textColorDisabled,
        },
        background: {
          paper: style.paper,
          default: style.default,
        },
      },
    });

    theme.typography.h1 = {
      fontWeight: "300",
      color: style.textColorPrimary,
      fontSize: "3rem",
    };

    theme.typography.h2 = {
      fontWeight: "300",
      color: style.textColorPrimary,
      fontSize: "2.5rem",
    };

    theme.typography.h3 = {
      fontWeight: "300",
      color: style.textColorPrimary,
      fontSize: "2rem",
    };

    theme.typography.h4 = {
      fontWeight: "300",
      color: style.textColorPrimary,
      fontSize: "1.75rem",
    };

    theme.typography.h5 = {
      fontWeight: "300",
      color: style.textColorPrimary,
      fontSize: "1.50rem",
    };

    theme.typography.h6 = {
      fontWeight: "300",
      color: style.textColorPrimary,
      fontSize: "1.25rem",
    };

    theme.typography.body1 = {
      fontWeight: "300",
      color: style.textColorPrimary,
      fontSize: "1.25rem",
    };
    theme.typography.body2 = {
      fontWeight: "300",
      color: style.textColorPrimary,
      fontSize: "1rem",
    };
    theme.typography.subtitle1 = {
      fontWeight: "400",
      color: style.textColorPrimary,
      fontSize: "1rem",
    };
    theme.typography.subtitle2 = {
      fontWeight: "500",
      color: style.textColorPrimary,
      fontSize: "0.875rem",
    };
  } else {
    var theme = createTheme({
      palette: {
        mode: "dark",
      },
    });
    theme.typography.h1 = {
      fontWeight: "300",
      fontSize: "3rem",
    };

    theme.typography.h2 = {
      fontWeight: "300",
      fontSize: "2.5rem",
    };

    theme.typography.h3 = {
      fontWeight: "300",
      fontSize: "2rem",
    };

    theme.typography.h4 = {
      fontWeight: "300",
      fontSize: "1.75rem",
    };

    theme.typography.h5 = {
      fontWeight: "300",
      fontSize: "1.50rem",
    };

    theme.typography.h6 = {
      fontWeight: "300",
      fontSize: "1.25rem",
    };

    theme.typography.body1 = {
      fontWeight: "300",
      fontSize: "1.25rem",
    };
    theme.typography.body2 = {
      fontWeight: "300",
      fontSize: "1rem",
    };
    theme.typography.subtitle1 = {
      fontWeight: "400",
      fontSize: "1rem",
    };
    theme.typography.subtitle2 = {
      fontWeight: "500",
      fontSize: "0.875rem",
    };
  }

  return { theme };
};

export default useTheme;
