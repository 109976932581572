import { Compass } from "../compass/compass";
import {
  Grid,
  Button,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Avatar,
  Box,
} from "@mui/material";
import React, { useState, useEffect, useRef, Fragment } from "react";
import _ from "lodash";
import LatLon, { Dms } from "geodesy/latlon-spherical.js";
import { useDeviceOrientation } from "../../hooks/deviceOrientation";
import { animated, useSpring } from "@react-spring/web";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectApp, setTheme } from "../../store/appReducerSlice";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

export const OutsideZone = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const [nearBy, setNearBy] = useState<any>([]);
  const [compass, setCompass] = useState<any>(null);
  const [displayZones, setDisplayZones] = useState<any>();
  const { getDir } = useDeviceOrientation();

  // @ts-ignore
  const coords = window.coords;

  const compassAnimate = useSpring({
    opacity: compass ? 1 : 0,
    y: compass ? 0 : 24,
  });

  useEffect(() => {
    dispatch(setTheme(app));
    const _nearBy: any = [];
    for (var i in app.locations) {
      const _zones = [];
      for (const z in app.locations[i].settings.zones) {
        _zones.push({
          distance: LatLon.parse(
            coords?.latitude as number,
            coords?.longitude as number
          ).distanceTo(
            LatLon.parse(
              app.locations[i].settings.zones[z].lat,
              app.locations[i].settings.zones[z].long
            )
          ),
          bearing: LatLon.parse(
            coords?.latitude as number,
            coords?.longitude as number
          ).finalBearingTo(
            LatLon.parse(
              app.locations[i].settings.zones[z].lat,
              app.locations[i].settings.zones[z].long
            )
          ),
          zone: app.locations[i].settings.zones[z],
        });
      }
      if (_zones.length > 0) {
        _nearBy.push({
          zones: _zones,
          zone: app.locations[i],
        });
      }
    }
    setNearBy(_nearBy);
  }, []);
  return (
    <>
      <Box
        component="div"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
        }}
      >
        {compass ? (
          <>
            <animated.div style={compassAnimate}>
              <Compass zone={compass} />
            </animated.div>
          </>
        ) : (
          <Box component="div" textAlign="center">
            <Typography
              variant="h3"
              component="div"
              sx={{
                flexGrow: 1,
                m: 3,
              }}
            >
              You are outside of a Zone
            </Typography>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              Nearby Zones
            </Typography>
          </Box>
        )}
        <Box
          component="div"
          sx={{
            mt: 1,
            mb: 5,
            width: "100%",
            bgcolor: "#ffffff15",
            borderRadius: 2,
          }}
        >
          <List dense={false}>
            {nearBy.map((location: any, index: any) => (
              <Fragment key={index}>
                <ListItemButton
                  key={index}
                  sx={{
                    bgcolor: "#00000005",
                    borderBottom: "1px solid #00000050",
                  }}
                  onClick={() => {
                    // nothing yet
                    if (displayZones == index) {
                      setDisplayZones(null);
                    } else {
                      setDisplayZones(index);
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{ bgcolor: "rgba(113, 137, 180, 0.42)" }}
                      src={
                        location.zone.settings.logo
                          ? location.zone.settings.logo.src
                          : "/logo192.png"
                      }
                    ></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={location.zone.settings.name}
                    secondary={location.zones.length + " zones"}
                  />
                </ListItemButton>
                {displayZones == index && (
                  <>
                    {location.zones.map((zone: any, idx: any) => (
                      <Fragment key={idx}>
                        <ListItemButton
                          key={index}
                          sx={{ borderBottom: "1px solid #00000050" }}
                          onClick={() => {
                            // nothing yet
                            setCompass(zone);
                          }}
                        >
                          <ListItemText
                            primary={zone.zone.id}
                            secondary={
                              Math.round(zone.distance) / 1000 +
                              "km " +
                              getDir(zone.bearing)
                            }
                          />
                          {zone.zone?.xr && <ViewInArIcon />}
                        </ListItemButton>
                      </Fragment>
                    ))}
                  </>
                )}
              </Fragment>
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
};
