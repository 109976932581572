import React, { Suspense, useEffect, useState } from "react";
import { Interactive, XR, XRButton, Controllers } from "@react-three/xr";
import { Text } from "@react-three/drei";
//import './styles.css'
import { Canvas } from "@react-three/fiber";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { selectZone } from "../../store/appReducerSlice";
import { ArCompass } from "../xr/ArCompass";

export const ZoneXR = () => {
  const zone = useAppSelector(selectZone)[0];
  const [canvas, setCanvas] = useState<any>(null);
  console.log(zone);
  return (
    <>
      <Box component="div" textAlign="center">
        <Typography
          variant="h1"
          component="div"
          sx={{ flexGrow: 1, mt: 3, mb: 6 }}
        >
          AR TITLE
        </Typography>
      </Box>

      <Typography
        variant="body1"
        component="div"
        sx={{ ml: 1, mr: 1, flexGrow: 1 }}
      >
        Description of Ar
      </Typography>
      <XRButton
        mode="AR"
        sessionInit={{
          // @ts-ignore
          domOverlay:
            typeof document !== "undefined"
              ? { root: document.body }
              : undefined,
          optionalFeatures: [
            "hit-test",
            "dom-overlay",
            "dom-overlay-for-handheld-ar",
          ],
        }}
        style={{
          position: "absolute",
          bottom: "64px",
          left: "50%",
          transform: "translateX(-50%)",
          padding: "12px 24px",
          border: "1px solid white",
          borderRadius: "4px",
          background: "rgba(0, 0, 0, 0.1)",
          color: "white",
          font: "normal 0.8125rem sans-serif",
          outline: "none",
          zIndex: 9001,
          cursor: "pointer",
        }}
      />
      <Canvas
        style={{ zIndex: 9000, height: 1 }}
        onCreated={(state) => {
          setCanvas(state.gl.domElement);
        }}
      >
        {zone.xrType === "system" && zone.xrSystem === "compass" && (
          <ArCompass />
        )}
      </Canvas>
    </>
  );
};
