export const navigatorData = () => {
  let x: string = "";
  for (var p in navigator) {
    if (
      [
        "vendor",
        "appCodeName",
        "appName",
        "appVersion",
        "platform",
        "product",
        "userAgent",
        "language",
      ].includes(p)
    ) {
      try {
        x += p + "=" + navigator[p] + "\n";
      } catch (e) {
        console.error(e);
      }
    }
  }
  return x;
};
