import _ from "lodash";
import Screen from "./components/screen/screen";
import {
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { GRAPH_ENPOINT, GRAPH_SUBSCRIPTIONS_ENPOINT } from "./graphs/graphs";
import useAPI from "./hooks/api";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { selectHash, setHash, setApp } from "./store/appReducerSlice";

function App() {
  /*  const httpLink = new HttpLink({
    uri: GRAPH_ENPOINT,
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: GRAPH_SUBSCRIPTIONS_ENPOINT as string,
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },

    wsLink,

    httpLink
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  }); */

  return (
    <>
      <Provider store={store}>
        {/* <ApolloProvider client={client}> */}
        <Screen />
        {/* </ApolloProvider> */}
      </Provider>
    </>
  );
}

export default App;
