import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectApp,
  selectPage,
  selectZoneApp,
  setPage,
} from "../../store/appReducerSlice";

export const ZoneAppBar = () => {
  const app = useAppSelector(selectApp);
  const zoneApp = useAppSelector(selectZoneApp);
  const page = useAppSelector(selectPage);
  const dispatch = useAppDispatch();
  const [menuIndex, setMenuIndex] = React.useState<null | number>(null);
  const [menuItemIndex, setMenuItemIndex] = React.useState<number>(0);

  const [anchorElNav, setAnchorElNav] = React.useState<any>([]);

  const handleOpenNavMenu = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    const _anchorElNav = { ...anchorElNav };
    _anchorElNav[index] = event.currentTarget;
    setAnchorElNav(_anchorElNav);
  };

  const handleCloseNavMenu = (index: number) => {
    const _anchorElNav = { ...anchorElNav };
    _anchorElNav[index] = null;
    setAnchorElNav(_anchorElNav);
  };

  useEffect(() => {
    if (menuIndex !== null) {
      /*   app.setState((appState: any) => ({
        ...appState,
        page: app.state.app.settings?.header?.menu[menuIndex]?.items[
          menuItemIndex
        ].page,
      }));*/
    }
  }, [zoneApp]);
  return (
    <>
      {zoneApp?.settings?.header && zoneApp?.settings?.header.enabled && (
        //app.state?.inzone &&
        // app.state?.inzone.length !== 0 && (
        <>
          <AppBar position="fixed" style={{ zIndex: 1000 }}>
            <Toolbar variant="dense" disableGutters={true}>
              {zoneApp.settings?.header?.logo && (
                <Box
                  component="img"
                  sx={{
                    height: 40,
                    width: 40,
                    m: 1,
                  }}
                  src={zoneApp.settings.header.logo}
                />
              )}
              {zoneApp.settings?.header?.menu && (
                <>
                  <Box component="div" sx={{ flexGrow: 1 }} textAlign="right">
                    {page?.content ? (
                      <>
                        <Button
                          color="inherit"
                          sx={{ mr: 0.2 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setMenuIndex(null);
                            /*app.setState((appState: any) => ({
                              ...appState,
                              page: {},
                            }));*/
                            dispatch(setPage({}));
                          }}
                        >
                          Back
                        </Button>
                      </>
                    ) : (
                      zoneApp.settings?.header?.menu.map(
                        (menu: any, index: any) => (
                          <React.Fragment key={index}>
                            <Button
                              color="inherit"
                              sx={{ mr: 0.2 }}
                              onClick={(event) =>
                                handleOpenNavMenu(event, index)
                              }
                            >
                              {menu.text}
                            </Button>
                            <Menu
                              id="menu-appbar"
                              anchorEl={anchorElNav[index]}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              open={Boolean(anchorElNav[index])}
                              onClose={() => {
                                handleCloseNavMenu(index);
                              }}
                            >
                              {menu.items.map((item: any, _index: any) => (
                                <MenuItem
                                  key={_index}
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                    setMenuIndex(index);
                                    setMenuItemIndex(_index);
                                    /* app.setState((appState: any) => ({
                                      ...appState,
                                      page: item.page,
                                    }));*/
                                    dispatch(setPage(item.page));
                                    console.log(item.page);
                                    handleCloseNavMenu(index);
                                  }}
                                >
                                  <Typography textAlign="center">
                                    {item.text}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Menu>
                          </React.Fragment>
                        )
                      )
                    )}
                  </Box>
                </>
              )}
            </Toolbar>
          </AppBar>
          <div style={{ height: "56px", display: "block" }} />
        </>
      )}
    </>
  );
};
