import React from "react";
import { Page } from "../page/page";
export interface inZoneProps {
  inzone: any[];
}
export const InZone = ({ inzone }: inZoneProps) => {
  return (
    <>
      {inzone.map((app: any, index: any) => (
        <React.Fragment key={index}>
          <Page page={app} />
        </React.Fragment>
      ))}
    </>
  );
};
