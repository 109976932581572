import { ReactNode } from "react";
import { setLastSection } from "../../store/appReducerSlice";
import { useAppDispatch } from "../../store/hooks";

interface TransitionProps {
  children: ReactNode;
  animateOut?: boolean;
  type?: string;
}
const blendInStyle = {
  top: 0,
  left: 0,
  transition: "all linear 1s",
  opacity: 1,
  // position: "absolute",
  zIndex: 10,
};

const blendOutStyle = {
  transition: "all linear 500ms",
  opacity: 0,
  zIndex: 9,
};

const blurOutStyle = {
  filter: "blur(10px)",
  transition: "all linear 500ms",
  opacity: 0,
  zIndex: 9,
};

export const Transition = ({
  children,
  animateOut = false,
  type = "blend",
}: TransitionProps) => {
  let style: any = blendInStyle;
  if (animateOut === true) {
    style = blendOutStyle;
    if (type === "blur") {
      style = blurOutStyle;
    }
  }

  return <div style={style}>{children}</div>;
};

interface TransitionWrapProps {
  children: ReactNode;
  matched: string;
  section: string | null;
  lastSection: string | null;
}

export const TransitionWrap = ({
  children,
  matched,
  section,
  lastSection,
}: TransitionWrapProps) => {
  const dispatch = useAppDispatch();
  if (lastSection === section && section === matched) {
    return <Transition>{children}</Transition>;
  }
  if (matched === lastSection) {
    setTimeout(() => {
      dispatch(setLastSection(null));
    }, 1000);
    return <Transition animateOut={true}>{children}</Transition>;
  } else if (matched === section) {
    return <Transition>{children}</Transition>;
  }
  return null;
};
