import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { useAppDispatch } from "../../store/hooks";

export interface zoneProps {
  app: any;
  zone: any;
  setPage: any;
}

export const PreviewAppBar = ({ app, zone, setPage }: zoneProps) => {
  const dispatch = useAppDispatch();
  const [menuIndex, setMenuIndex] = React.useState<null | number>(null);
  const [menuItemIndex, setMenuItemIndex] = React.useState<number>(0);

  const [anchorElNav, setAnchorElNav] = React.useState<any>([]);

  const handleOpenNavMenu = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    const _anchorElNav = { ...anchorElNav };
    _anchorElNav[index] = event.currentTarget;
    setAnchorElNav(_anchorElNav);
  };

  const handleCloseNavMenu = (index: number) => {
    const _anchorElNav = { ...anchorElNav };
    _anchorElNav[index] = null;
    setAnchorElNav(_anchorElNav);
  };

  /*useEffect(() => {
    if (menuIndex !== null) {
      //dispatch(setPage())
      app.setState((appState: any) => ({
        ...appState,
        page: app.state.app.settings?.header?.menu[menuIndex]?.items[
          menuItemIndex
        ].page,
      }));
    }
  }, [app.state?.app]);*/

  const _app = useMemo(() => {
    return app.locations.find((location: any) => {
      return location.settings.zones.find((_zone: any) => {
        return _zone?._id === zone.zone?._id;
      });
    });
  }, [app.locations, zone.zone]);

  return (
    <>
      {_app?.settings?.header && _app?.settings?.header.enabled && (
        <>
          <AppBar position="fixed" style={{ zIndex: 1000 }}>
            <Toolbar variant="dense" disableGutters={true}>
              {_app.settings?.header?.logo && (
                <Box
                  component="img"
                  sx={{
                    height: 40,
                    width: 40,
                    m: 1,
                  }}
                  src={_app.settings.header.logo}
                />
              )}
              {_app.settings?.header?.menu && (
                <>
                  <Box component="div" sx={{ flexGrow: 1 }} textAlign="right">
                    {menuIndex !== null ? (
                      <>
                        <Button
                          color="inherit"
                          sx={{ mr: 0.2 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setMenuIndex(null);
                            setPage(zone.zone);
                          }}
                        >
                          Back
                        </Button>
                      </>
                    ) : (
                      _app.settings?.header?.menu.map(
                        (menu: any, index: any) => (
                          <React.Fragment key={index}>
                            <Button
                              color="inherit"
                              sx={{ mr: 0.2 }}
                              onClick={(event) =>
                                handleOpenNavMenu(event, index)
                              }
                            >
                              {menu.text}
                            </Button>
                            <Menu
                              id="menu-appbar"
                              anchorEl={anchorElNav[index]}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              open={Boolean(anchorElNav[index])}
                              onClose={() => {
                                handleCloseNavMenu(index);
                              }}
                            >
                              {menu.items.map((item: any, _index: any) => (
                                <MenuItem
                                  key={_index}
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                    setMenuIndex(index);
                                    setMenuItemIndex(_index);
                                    setPage(item.page);
                                    //console.log(item.page);
                                    handleCloseNavMenu(index);
                                  }}
                                >
                                  <Typography textAlign="center">
                                    {item.text}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Menu>
                          </React.Fragment>
                        )
                      )
                    )}
                  </Box>
                </>
              )}
            </Toolbar>
          </AppBar>
          <div style={{ height: "56px", display: "block" }} />
        </>
      )}
    </>
  );
};
