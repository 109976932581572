import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  Marker,
  SVGOverlay,
  Circle,
  CircleMarker,
} from "react-leaflet";
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useEffect, useRef, useState } from "react";
import { Snackbar, Typography } from "@mui/material";
import useGeoMath from "../../hooks/geoMath";
import React from "react";
import PropTypes from "prop-types";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Preview } from "../preview/preview";
import {
  selectApp,
  selectLocalApps,
  selectMapPoint,
  setMapPoint,
  setNavItem,
  setSection,
  setTheme,
} from "../../store/appReducerSlice";
export interface props {
  app: any;
  coords: any;
  setScreen: Function;
  locations: any;
}

export const Map = () => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const mapPoint = useAppSelector(selectMapPoint);

  const locations = useAppSelector(selectLocalApps);
  const { getBoundingBoxMap } = useGeoMath();
  const [bounds, setBounds] = useState<any>();
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [markers, setMarkers] = useState<any>();
  const [preview, setPreview] = useState<boolean>(false);
  const [zone, setZone] = useState<any>(null);

  // @ts-ignore
  const coords = window.coords;

  const doSetPreview = (value: any) => {
    setPreview(value);
    setZone(null);
  };
  const doPreview = (id: any) => {
    //   dispatch(setPreview())
    for (let i = 0; i < app.locations.length; i++) {
      const l = app.locations[i].settings.zones.find((_zone: any) => {
        return _zone?._id === id;
      });
      if (l) {
        dispatch(setTheme(l));
        setZone({
          zone: l,
        });
      }
    }

    const _app = app.locations.find((location: any) => {
      return location.settings.zones.find((_zone: any) => {
        return _zone?._id === id;
      });
    });
    dispatch(setTheme(_app));
    setPreview(true);
  };
  // @ts-ignore
  window.doPreview = doPreview;

  let popupRef = useRef();
  const [ref, setRef] = useState<any>();
  const [pointDisplay, setPointDisplay] = useState<any>(null);
  useEffect(() => {
    if (mapPoint) {
      setPointDisplay({ ...mapPoint });
      dispatch(setMapPoint(null));
    }
  }, [mapPoint]);
  useEffect(() => {
    if (pointDisplay) {
      ref.openPopup();
    }
  }, [ref]);
  useEffect(() => {
    // get all coords

    const lat: any = [];
    const long: any = [];
    for (var i in app.locations) {
      for (var j in app.locations[i].locations) {
        lat.push(app.locations[i].locations[j].lat);
        long.push(app.locations[i].locations[j].long);
      }
    }
    lat.sort((a: any, b: any) => {
      return a - b;
    });
    long.sort((a: any, b: any) => {
      return a - b;
    });
    setBounds([
      [lat[lat.length - 1], long[0]],
      [lat[0], long[long.length - 1]],
    ]);
    const _markers: any = [];
    app.locations.map((_app: any, index: any) =>
      _app.settings.zones.map((zone: any, _index: any) => {
        const xr = zone.xr
          ? `<svg width="30" style="float:right; color:gray" viewBox="0 0 24 24" data-testid="ViewInArIcon"><path d="m18.25 7.6-5.5-3.18c-.46-.27-1.04-.27-1.5 0L5.75 7.6c-.46.27-.75.76-.75 1.3v6.35c0 .54.29 1.03.75 1.3l5.5 3.18c.46.27 1.04.27 1.5 0l5.5-3.18c.46-.27.75-.76.75-1.3V8.9c0-.54-.29-1.03-.75-1.3zM7 14.96v-4.62l4 2.32v4.61l-4-2.31zm5-4.03L8 8.61l4-2.31 4 2.31-4 2.32zm1 6.34v-4.61l4-2.32v4.62l-4 2.31zM7 2H3.5C2.67 2 2 2.67 2 3.5V7h2V4h3V2zm10 0h3.5c.83 0 1.5.67 1.5 1.5V7h-2V4h-3V2zM7 22H3.5c-.83 0-1.5-.67-1.5-1.5V17h2v3h3v2zm10 0h3.5c.83 0 1.5-.67 1.5-1.5V17h-2v3h-3v2z"></path></svg>`
          : "";
        _markers.push({
          position: {
            lat: zone.lat,
            long: zone.long,
          },
          icon: new Icon({
            iconUrl: _app.settings?.logo
              ? _app.settings.logo.src
              : "/logo192.png",
            iconSize: [30, 30],
          }),
          text: `<h3>${zone.id}</h3><div style="fontSize:16px;">${zone.info}</div>
          <div style="margin-top: 10px;">
            <a href="javascript:window.doPreview('${zone._id}');">
              <svg width="30" viewBox="0 0 24 24"><path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"></path></svg>
            </a>
            ${xr}
          </div>`,
        });
      })
    );
    setMarkers(_markers);
  }, []);

  useEffect(() => {
    if (zone) {
      setSnack(false);
    } else {
      if (locations.length > 0) {
        setSnack(true);
      } else {
        setSnack(false);
      }
    }
    var zoneText = "a Zone";
    if (locations.length > 1) {
      zoneText = locations.length + " Zones";
    }
    setMessage(`You are in ${zoneText}`);
  }, [app.inzone, zone]);
  return (
    <>
      {zone && (
        <Preview
          preview={preview}
          setPreview={doSetPreview}
          zone={zone}
          app={app}
          mapExit={false}
        />
      )}
      {bounds && (
        <MapContainer
          //@ts-ignore
          // center={[coords.latitude, coords.longitude]}
          // zoom={15}
          className="markercluster-map"
          bounds={bounds}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            zIndex: 1001,
          }}
          //className={styles.container}
          doubleClickZoom
          tap={false}
        >
          <TileLayer
            //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {markers && <MarkerCluster markers={markers} />}
          {/*app.locations.map((_app: any, index: any) => (
              <React.Fragment key={index}>
                  {_app.settings.zones.map((zone: any, _index: any) => (
                      //@ts-ignore
                      <Marker key={_index} position={[zone.lat, zone.long]} icon={new Icon({ iconUrl: _app.settings?.logo ? _app.settings.logo.src : '/logo192.png', iconSize: [25, 25] })}>

                          {<Popup

                          >
                              <Typography variant={'h5'} sx={{ color: "#000" }}>{zone.id}</Typography>
                              <Typography variant={'body1'} sx={{ color: "#000" }}>{zone.info}</Typography>
                          </Popup>}
                      </Marker>
                  ))}
              </React.Fragment>
          ))*/}

          {pointDisplay && (
            <Marker
              ref={(r) => {
                setRef(r);
              }}
              position={[pointDisplay.lat, pointDisplay.long]}
              //@ts-ignore
              icon={
                new Icon({
                  iconUrl: "/assets/img/point.png",
                  iconSize: [30, 30],
                  iconAnchor: [15, 30],
                })
              }
              zIndexOffset={1000}
            >
              <Popup>
                <Typography variant={"h6"} sx={{ color: "#000" }}>
                  {pointDisplay.id}
                </Typography>
                <Typography variant={"body2"} sx={{ color: "#000" }}>
                  {pointDisplay.info}
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <a onClick={() => doPreview(pointDisplay._id)}>
                    <svg width="30" viewBox="0 0 24 24">
                      <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"></path>
                    </svg>
                  </a>
                  {pointDisplay?.xr && (
                    <span style={{ color: "green" }}>
                      <svg
                        width="30"
                        style={{ float: "right", color: "green" }}
                        viewBox="0 0 24 24"
                        data-testid="ViewInArIcon"
                      >
                        <path
                          fill="gray"
                          d="m18.25 7.6-5.5-3.18c-.46-.27-1.04-.27-1.5 0L5.75 7.6c-.46.27-.75.76-.75 1.3v6.35c0 .54.29 1.03.75 1.3l5.5 3.18c.46.27 1.04.27 1.5 0l5.5-3.18c.46-.27.75-.76.75-1.3V8.9c0-.54-.29-1.03-.75-1.3zM7 14.96v-4.62l4 2.32v4.61l-4-2.31zm5-4.03L8 8.61l4-2.31 4 2.31-4 2.32zm1 6.34v-4.61l4-2.32v4.62l-4 2.31zM7 2H3.5C2.67 2 2 2.67 2 3.5V7h2V4h3V2zm10 0h3.5c.83 0 1.5.67 1.5 1.5V7h-2V4h-3V2zM7 22H3.5c-.83 0-1.5-.67-1.5-1.5V17h2v3h3v2zm10 0h3.5c.83 0 1.5-.67 1.5-1.5V17h-2v3h-3v2z"
                        ></path>
                      </svg>
                    </span>
                  )}
                </div>
              </Popup>
            </Marker>
          )}

          <Marker
            position={[coords.latitude, coords.longitude]}
            //@ts-ignore
            icon={
              new Icon({
                iconUrl: "/assets/img/location.png",
                iconSize: [25, 25],
                iconAnchor: [0, 25],
              })
            }
            zIndexOffset={1000}
          />
        </MapContainer>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snack}
        onClick={() => {
          //setScreen("main");
          dispatch(setNavItem("zones"));
          dispatch(setSection("zone-select"));
        }}
        message={message}
        sx={{ bottom: 62 }}
      />
    </>
  );
};

const mcg = L.markerClusterGroup({ maxClusterRadius: 10 });

const MarkerCluster = ({ markers }: any) => {
  const map = useMap();

  useEffect(() => {
    mcg.clearLayers();
    markers.forEach(({ position, icon, text }: any) =>
      L.marker(new L.LatLng(position.lat, position.long), {
        icon: icon,
      })
        .addTo(mcg)
        .bindPopup(text)
    );

    map.addLayer(mcg);
  }, [markers, map]);

  return null;
};

MarkerCluster.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.objectOf(PropTypes.number).isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
