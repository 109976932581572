import React, { useState, useEffect, useRef } from "react";
import {
  Drawer,
  Grid,
  Button,
  Typography,
  Box,
  Link,
  Zoom,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectApp, setSection } from "../../store/appReducerSlice";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
export const Welcome = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(selectApp);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<any>();
  console.log(appState);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      component="div"
    >
      <Drawer
        anchor="top"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        style={{ zIndex: 8000 }}
      >
        <div
          style={{ padding: 20, paddingBottom: 64 }}
          dangerouslySetInnerHTML={{ __html: drawerContent }}
        />
        <BottomNavigation
          style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 9001 }}
        >
          <BottomNavigationAction
            onClick={() => {
              setShowDrawer(false);
            }}
            label="Recents"
            icon={<ExitToAppOutlinedIcon />}
          />
        </BottomNavigation>
      </Drawer>

      <Zoom in={true}>
        <Grid container sx={{ maxWidth: "500px" }}>
          <BrowserView style={{ width: "100%" }}>
            <Alert severity="warning" sx={{ mb: 6 }}>
              <AlertTitle>Warning</AlertTitle>
              This App is designed for mobile devices
            </Alert>
          </BrowserView>
          <MobileView>
            <Grid item xs={12}>
              <Box component="div" textAlign="center" sx={{ mt: 1 }}>
                <img
                  //height="200"
                  src="logo192.png"
                  alt="logo"
                />
              </Box>
              <Box component="div" sx={{ m: 5 }}>
                <Typography
                  variant="h2"
                  textAlign="center"
                  component="div"
                  sx={{ mb: 4 }}
                >
                  {appState?.title?.value}
                </Typography>
                <Typography
                  sx={{ mt: 1.5, mb: 2, textAlign: "center" }}
                  variant="h4"
                >
                  requires access to
                </Typography>
                <Typography variant="body1" component="div">
                  <ul
                    style={{
                      marginTop: "10px",
                      listStyle: "none",
                      padding: 0,
                      textAlign: "center",
                    }}
                  >
                    <li style={{ marginBottom: "10px" }}>
                      GPS / Location Service
                    </li>
                    <li style={{ marginBottom: "10px" }}>Gyroscope / Motion</li>
                    <li>Camera</li>
                  </ul>
                  <Typography
                    sx={{ mt: 1.5, mb: 2, textAlign: "center" }}
                    variant="body1"
                  >
                    By continuing you are accepting our{" "}
                    <Link
                      onClick={() => {
                        setDrawerContent(appState?.terms?.value);
                        setShowDrawer(true);
                      }}
                      color="inherit"
                    >
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                      onClick={() => {
                        setDrawerContent(appState?.privacy?.value);
                        setShowDrawer(true);
                      }}
                      color="inherit"
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                </Typography>
              </Box>
              <Box component="div" textAlign="center">
                <Button
                  id="addSubmit"
                  sx={{ mb: 3 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(setSection("geolocation"));
                    // app.state["section"] = "geolocation";

                    /* app.setState((appState: any) => ({
                    ...appState,
                    fullScreen: true,
                    section: "geolocation",
                  })); */
                  }}
                >
                  OK
                </Button>
              </Box>
            </Grid>
          </MobileView>
        </Grid>
      </Zoom>
    </Box>
  );
};
