import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectApp,
  selectCapabilities,
  selectLocalApps,
  setNavItem,
  setSection,
  setStatistics,
  setTheme,
  setTitle,
  setZone,
  setZoneApp,
} from "../../store/appReducerSlice";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { navigatorData } from "../../libs/navigator";

export const ZoneSystemMenu = () => {
  const dispatch = useAppDispatch();
  const capabilities = useAppSelector(selectCapabilities);
  const app = useAppSelector(selectApp);
  const apps = useAppSelector(selectLocalApps);

  const _navigator = useMemo(() => {
    return navigatorData();
  }, []);

  useEffect(() => {
    dispatch(setTheme(app));
  }, []);

  useEffect(() => {
    if (apps.length == 0) {
      dispatch(setSection("zone"));
    }
  }, [app]);

  return (
    <>
      <Box
        component="div"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
        }}
      >
        {apps.length > 1 && (
          <>
            <Box component="div" textAlign="center">
              <Grid item>
                <Typography
                  variant="h1"
                  component="div"
                  sx={{ flexGrow: 1, mt: 3 }}
                >
                  Select Zone
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, m: 2 }}
                >
                  Multiple Zones are found at this location.
                </Typography>
              </Grid>
            </Box>
            <Box
              component="div"
              sx={{
                mt: 1,
                width: "100%",
                bgcolor: "#ffffff15",
                borderRadius: 2,
              }}
            >
              <List dense={false}>
                {apps.map((_app: any, index: any) => (
                  <ListItemButton
                    key={index}
                    sx={{
                      bgcolor: "#00000005",
                      borderBottom: "1px solid #00000050",
                    }}
                    onClick={() => {
                      const statistics = {
                        browser: _navigator,
                        zoneApp: _app[0].id,
                        zone: _app[1]._id,
                        section: "zone",
                        timedate: new Date().getTime(),
                        camera: capabilities?.camera,
                        gps: capabilities?.gps,
                        xr: capabilities?.xr,
                        giro: capabilities?.giro,
                      };
                      dispatch(setStatistics(statistics));

                      dispatch(setNavItem("main"));
                      dispatch(setSection("zone"));
                      dispatch(setTitle(_app[0].name));
                      dispatch(setZoneApp(_app[0]));
                      dispatch(setZone([_app[1]]));
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{ bgcolor: "rgba(113, 137, 180, 0.42)" }}
                        src={
                          _app[0].settings.logo
                            ? _app[0].settings.logo.src
                            : "/logo192.png"
                        }
                      ></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={_app[0].settings.name}
                      secondary={_app[1].id}
                    />
                    {_app[1].xr && <ViewInArIcon />}
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
