import { useEffect } from "react";
import { useDeviceOrientation } from "../../hooks/deviceOrientation";
import {
  selectGeolocationEnabled,
  setCapabilities,
} from "../../store/appReducerSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const Capabilities = () => {
  const dispatch = useAppDispatch();
  const isGeolocationEnabled = useAppSelector(selectGeolocationEnabled);
  const { events } = useDeviceOrientation();
  // @ts-ignore
  window.deviceOrientationEvents = events;

  useEffect(() => {
    const testCapabilites = async () => {
      let _capabilities = { camera: false, gps: true, xr: false, giro: false };
      //@ts-ignore
      const xrCapable = await navigator.xr.isSessionSupported("immersive-ar");
      //@ts-ignore
      await navigator.getUserMedia(
        { video: true },
        function () {
          // webcam is available
          _capabilities.camera = true;
        },
        function () {
          // webcam is not available
        }
      );
      _capabilities.xr = xrCapable;

      if (isGeolocationEnabled) {
        _capabilities.gps = true;
      }

      if (events?.events.alpha != undefined) {
        _capabilities.giro = true;
      }

      dispatch(setCapabilities(_capabilities));
    };
    testCapabilites();
  }, [isGeolocationEnabled]);
  return null;
};
