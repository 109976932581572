import { Box } from "@mui/material";
import { selectZoneApp } from "../../store/appReducerSlice";
import { useAppSelector } from "../../store/hooks";
import { Zone } from "./zone";

export const ZoneApp = () => {
  const zoneApp = useAppSelector(selectZoneApp);

  return (
    <Box
      component="div"
      sx={{
        backgroundImage: zoneApp?.settings?.style?.backgroundImage,
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        zIndex: 1002,
      }}
    >
      <Zone />
    </Box>
  );
};
