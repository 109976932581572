import { useState, useEffect, ReactNode } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Welcome } from "../welcome/welcome";
import useTheme from "../../hooks/theme";

import { DocumentFullScreen } from "@chiragrupani/fullscreen-react";
import { SET_STATISTICS, GET_LOCATIONS } from "../../graphs/graphs";
import _ from "lodash";
import { Box } from "@mui/material";
import { useQuery, useSubscription, gql, useMutation } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectApp,
  selectHash,
  selectLastSection,
  selectLastStatistics,
  selectSection,
  selectStatistics,
  selectTheme,
  setApp,
  setHash,
} from "../../store/appReducerSlice";
import { Capabilities } from "../capabilities/capabilities";
import { Gps } from "../gps/gps";
import { OutsideZone } from "../outsideZone/outsideZone";
import { GpsScreens } from "../gps/gpsScreens";
import { ZoneSystemMenu } from "../zone/zoneSystemMenu";
import { ZoneApp } from "../zone/zoneApp";
import { useSelector } from "react-redux";
import { XR } from "../xr/xr";
import { Settings } from "../settings/settings";
import { Navigation } from "../navigation/navigation";
import { Map } from "../map/map";
import { TransitionWrap } from "../Transition/Transition";
import useAPI from "../../hooks/api";
import { useInterval } from "usehooks-ts";

function Screen() {
  /*
  USING REST INSTEAD OF GRAPH QL FOR FOR STATS AS SERVER CAPTURES CLEITN IP IS EASIER
  const [
    setStatistics,
    { data: statData, loading: statLoading, error: statError },
  ] = useMutation(SET_STATISTICS);
  */
  const { postStatistics } = useAPI();
  /*const { loading, error, data } = useQuery(GET_LOCATIONS, {
    pollInterval: 10000,
  });*/

  //const { loading, error, data } = useSubscription(GET_LOCATIONS_SUBSCRIPTION);
  const section = useAppSelector(selectSection);
  const lastSection = useAppSelector(selectLastSection);
  const statistics = useAppSelector(selectStatistics);
  const lastStatistics = useAppSelector(selectLastStatistics);
  const selectedTheme = useAppSelector(selectTheme);
  const app = useSelector(selectApp);

  const { getHash } = useAPI();
  const dispatch = useAppDispatch();
  const hash = useAppSelector(selectHash);

  const callHash = async () => {
    const x: any = await getHash(hash || "1");
    if (x?.hash) {
      dispatch(setHash(x.hash));
      dispatch(setApp(x));
    }
  };

  useEffect(() => {
    callHash();
  }, []);

  useInterval(() => {
    callHash();
  }, 30000);

  useEffect(() => {
    if (statistics) {
      const _lastStatistics = lastStatistics || {};
      if (
        JSON.stringify({ ...statistics, date: null }) !==
        JSON.stringify({ ..._lastStatistics, date: null })
      ) {
        postStatistics(statistics);
        //setStatistics({ variables: { statistics: statistics } });
      }
    }
  }, [statistics, lastStatistics]);
  /*useEffect(() => {
    if (data?.getObject) {
      dispatch(setApp(data.getObject));
    }
  }, [data]);

  useEffect(() => {
    console.log(error);
  }, [error]);*/

  const { theme } = useTheme(selectedTheme ? selectedTheme : app);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          component="div"
          maxWidth="sm"
          sx={{ m: "auto" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {section !== "welcome" && (
            <>
              <Gps />
              <Capabilities />
            </>
          )}
          <TransitionWrap
            matched="welcome"
            section={section}
            lastSection={lastSection}
          >
            <Welcome />
          </TransitionWrap>
          <TransitionWrap
            matched="geolocation"
            section={section}
            lastSection={lastSection}
          >
            <GpsScreens />
          </TransitionWrap>
          <TransitionWrap
            matched="zone-select"
            section={section}
            lastSection={lastSection}
          >
            <ZoneSystemMenu />
          </TransitionWrap>
          <TransitionWrap
            matched="zone"
            section={section}
            lastSection={lastSection}
          >
            <ZoneApp />
          </TransitionWrap>
          <TransitionWrap
            matched="xr"
            section={section}
            lastSection={lastSection}
          >
            <XR />
          </TransitionWrap>
          <TransitionWrap
            matched="map"
            section={section}
            lastSection={lastSection}
          >
            <Map />
          </TransitionWrap>
          <TransitionWrap
            matched="outside-zone"
            section={section}
            lastSection={lastSection}
          >
            <OutsideZone />
          </TransitionWrap>
          <TransitionWrap
            matched="settings"
            section={section}
            lastSection={lastSection}
          >
            <Settings />
          </TransitionWrap>
          {/*section === "welcome" && <Welcome />}
          {section === "geolocation" && <GpsScreens />}
          {section === "zone-select" && <ZoneSystemMenu />}
          {section === "zone" && <ZoneApp />}
          {section === "xr" && <XR />}
          {section === "map" && <Map />}
          {section === "outside-zone" && <OutsideZone />}
          {section === "settings" && <Settings /> */}
          {[
            "xr",
            "map",
            "zone",
            "zone-select",
            "outside-zone",
            "settings",
          ].includes(section as string) && <Navigation />}
        </Box>
      </ThemeProvider>
    </>
  );
}

export default Screen;
